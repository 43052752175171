<template>
  <v-row>
    <v-col
      v-if="metrics.includes('providers')"
      :cols="$attrs.cols"
    >
      <material-stats-card
        color="blue lighten-1"
        icon="fa-light fa-user-doctor"
        :title="$t('charts.active_providers')"
        :value="stats.providers | numeral('0,0')"
        sub-icon="mdi-calendar"
        :sub-text="$t('charts.last_x_units', { value: '30', units: $tc('day', 2) })"
        :loading="loading.stats.providers"
        @refresh="fetchStats(['providers'], true)"
      />
    </v-col>
    <v-col
      v-if="metrics.includes('facilities')"
      :cols="$attrs.cols"
    >
      <material-stats-card
        color="red lighten-1"
        icon="fa-light fa-hospitals"
        :title="$t('charts.facilities_serviced')"
        :value="stats.facilities | numeral('0,0')"
        sub-icon="mdi-calendar"
        :sub-text="$t('charts.last_x_units', { value: '30', units: $tc('day', 2) })"
        :loading="loading.stats.facilities"
        @refresh="fetchStats(['facilities'], true)"
      />
    </v-col>

    <v-col
      v-if="metrics.includes('patients')"
      :cols="$attrs.cols"
    >
      <material-stats-card
        color="teal lighten-2"
        icon="fa-light fa-bed-pulse"
        :title="$t('charts.patients_tested')"
        :value="stats.patients | numeral('0,0')"
        sub-icon="mdi-calendar"
        :sub-text="$t('charts.last_x_units', { value: '30', units: $tc('day', 2) })"
        :loading="loading.stats.patients"
        @refresh="fetchStats(['patients'], true)"
      />
    </v-col>

    <v-col
      v-if="metrics.includes('microbes')"
      :cols="$attrs.cols"
    >
      <material-stats-card
        color="orange lighten-1"
        icon="fa-light fa-bacteria"
        :title="$t('charts.microbes_per_result')"
        :value="stats.microbes | numeral('0.00')"
        sub-icon="mdi-calendar"
        :sub-text="$t('charts.last_x_units', { value: '30', units: $tc('day', 2) })"
        :loading="loading.stats.microbes"
        @refresh="fetchStats(['microbes'], true)"
      />
    </v-col>
  </v-row>
</template>
<script>
import { mapState } from 'vuex'

  export default {
    props: {
      metrics: {
        type: Array,
        default: () => [
          'providers',
          'facilities',
          'patients',
          'microbes',
        ],
      },
    },
    computed: {
      ...mapState('charts', ['loading', 'stats']),
    },
    created () {
      if (Object.keys(this.stats).length === 0) {
        this.fetchStats(this.metrics)
      }
    },
    methods: {
      fetchStats (metrics, no_cache = false) {
        metrics.forEach(metric => {
          this.$set(this.$store.state.charts.loading.stats, metric, true)
        })
        this.axios.get('charts/stats', {
          params: {
            no_cache: no_cache,
            metrics: metrics,
          },
        })
          .then((res) => {
            for (const [key, value] of Object.entries(res.data)) {
              this.$set(this.$store.state.charts.stats, key, value)
              this.$set(this.$store.state.charts.loading.stats, key, false)
            }
          })
          .catch(err => {
            this.$toast.error(err.response?.data?.message || err)
          })
      },
    },
  }
</script>
